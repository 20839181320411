import React from 'react';
import { styles } from './Styles';

const Views = () => {
  return (
    <div className="px-10 flex flex-col items-center justify-center py-12" style={{ backgroundColor: '#fffffe'}}>
      <div className={styles.sectionTitle}>
        <div className="border-t border-black w-20 mr-3"></div>
        My Views
        <div className="border-t border-black w-20 ml-3"></div>
      </div>
      <p className=" text-center text-lg mb-12">
        I write causerie and manage my second brain to share my views. 🌱
      </p>
      <div className=" flex flex-col md:flex-row justify-center w-full max-w-4xl mx-auto space-y-8 md:space-y-0 md:space-x-16">
        <div className="flex flex-col items-center mb-8 md:mb-0 w-full md:w-1/2">
          <h2 className="text-2xl font-bold mb-4">My Blog 📝</h2>
          <p className="text-center mb-4">
            I share my thoughts on personal growth, technology, and the occasional quirky observation about life.
          </p>
          <a 
              href="https://blog.yubojing.com" 
              className="px-4 py-2 rounded" 
              style={{ backgroundColor: '#eaddcf', color: '#020826'}}
              target="_blank" 
              rel="noopener noreferrer"
            >
              Read My Blog
            </a>
        </div>
        <div className="flex flex-col items-center w-full md:w-1/2">
          <h2 className="text-2xl font-bold mb-4">My Wiki 📚</h2>
          <p className="text-center mb-4">
            I maintain a personal knowledge base, sharing insights on topics I'm passionate about.
          </p>
          <a href="https://yubojing.notion.site/Yubo-Jing-s-Wiki-b6e3c6c3dde04094afe6e022ffddf3fc" className="px-4 py-2 rounded" style={{ backgroundColor: '#eaddcf', color: '#020826'}} target='blank'>
            Check Out My Wiki
          </a>
        </div>
      </div>
    </div>
  );
};

export default Views;
